.GlobalNavigation.-mobileActive {
  z-index: $z-index-global-navigation--opened;
}

.GlobalNavigation.-fixed {
  z-index: $z-index-global-navigation--opened;
}

@include media-breakpoint-down(sm) {
  .GlobalNavigation {
    .js-nav-bar2__toggle:focus-visible {
      outline: none !important;
    }
  }
}

.GlobalNavigation__bar.nav-bar2 {
  z-index: $z-index-global-navigation;

  body.is-nav-active & {
    z-index: $z-index-global-navigation--opened;
  }

  body.-with-f-m-agency &.is-nav-fixed {
    margin-top: rem(32);
  }

  .nav-bar2__logo:focus-visible {
    img {
      outline: 2px solid $black;
    }
  }
}

body {
  // shift rotunda on bottom sticky button height
  @include media-breakpoint-down(lg) {
    &:has(.js-Rotunda):has(.FullCruiseDetailsPage) {
      .js-Rotunda {
        bottom: 100px;
      }
    }
  }
}
